<template>
  <div id="login">
    <!-- <PasswordReset v-if="showPasswordReset" @close="togglePasswordReset()"></PasswordReset> -->
    <section>
      <div class="col1">
        <router-link to="/us/en/"><h1>Wellthmark</h1></router-link>
        <router-link to="/portfolio/all"><h3>Your health is your <span class="sloganHighlight">wellth</span>. Bench<span class="sloganHighlight">mark</span> it here.</h3></router-link>
      </div>
      <div class="col2">
        <form @submit.prevent>
          <h2>Welcome back!</h2>
          <div>
            <label for="email1">Email</label>
            <input v-model.trim="loginForm.email" type="text" placeholder="you@email.com" id="email1" />
          </div>
          <div>
            <label for="password1">Password</label>
            <input v-model.trim="loginForm.password" type="password" placeholder="********" id="password1" />
          </div>
          <button @click="login()" :disabled="loginForm.email === '' || loginForm.password.length < 8" class="button">Log In</button>
          <div class="extras">
            <a @click="togglePasswordReset()">Forgot Password</a>
            <a @click="toggleForm()">Create an Account</a>
          </div>
        </form>
      </div>
    </section>
    <transition name="fade">
      <div v-if="showPasswordReset" class="modal">
        <div class="modal-content">
          <div @click="togglePasswordReset()" class="close">close</div>
          <h3>Reset Password</h3>
          <div v-if="!showSuccess">
            <p>Enter your email to reset your password</p>
            <form @submit.prevent>
              <input v-model.trim="resetLoginEmail" type="email" placeholder="me@email.com" />
            </form>
            <p v-if="errorMsg !== ''" class="error">{{ errorMsg }}</p>
            <button @click="resetPassword()" :disabled="resetLoginEmail === ''" class="button">Reset</button>
          </div>
          <p v-else>Success! Check your email for a reset link.</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { auth } from '@/firebase'

export default {
  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      showPasswordReset: false,
      showSuccess: false,
      errorMsg: '',
      resetLoginEmail: ''
    }
  },
  methods: {
    async resetPassword() {
      console.log(this.resetLoginEmail)
      this.errorMsg = ''

      try {
        await auth.sendPasswordResetEmail(this.resetLoginEmail)
        this.showSuccess = true
      } catch (err) {
        this.errorMsg = err.message
      }
    },
    toggleForm() {
      this.$router.push('/signup')
    },
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset
    },
    login() {
      this.$store.dispatch('login', {
        email: this.loginForm.email,
        password: this.loginForm.password
      })
    }
  }
}
</script>

<style lang="scss" scoped>

// colors
$primary: #3D4D62;
$secondary: #7399C6;
$error: #ef5777;
$darkGreen: #1FA482;
$lightGreen: #5CD0B5;
$lightest: #e3f2fa;
$light: #c3d6e2;
$medium: #7399C6;
$dark: #3D4D62;
$white: #FFFFFF;
$deteriorating: #ef5777;
$improving: #1FA482;
$greyedOut: #787f85;

// fonts
$primaryFont: 'Playfair Display';
$myFontSize: 18px;
$myWidth: 680px;

// login
#login {
  background: linear-gradient(to right, $white 0%, $white 50%, $primary 50%, $primary 100%);

  @media screen and (max-width: 800px) {
    // height: 100vh;
    background: $primary;
  }

  section {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0;

    .col1 {
      flex: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5vh;

      h3 {
        color: $secondary;
        line-height: 1.5;
      }

      @media screen and (max-width: 800px) {
        display: none;
        // height: auto;
        // padding-top: 20vh;
      }
    }

    .col2 {
      flex: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5vh;

      h2 {
        color: $white;
      }

      // @media screen and (max-width: 742px) {
      //   height: auto;
      //   padding-top: 20vh;
      // }
    }
  }

  .col1 {
    color: $white;
    @media screen and (max-width: 800px) {
      display: none;
    }

    p {
      max-width: 490px;
      margin-top: 2rem;
      line-height: 1.8;
    }

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .col2 {
    h1 {
      margin-bottom: 2rem;
    }

    form {
      max-width: 450px;
      margin: 0 auto;
    }

    .extras {
      float: right;
      text-align: right;

      a {
        display: block;
        margin-bottom: 0.5rem;
        color: $secondary;
        // padding: 0.5rem;

        &:hover {
          // background-color: #000;
          color: $lightest;
        }
      }
    }

    .password-reset {
      h1 {
        margin-bottom: 1rem;
      }

      p {
        margin-bottom: 2rem;
      }
    }
  }
}

// password reset
.modal {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(#333, 0.5);

  .modal-content {
    position: relative;
    margin: auto;
    width: 100%;
    max-width: 400px;
    background: #fff;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(#333, 0.5);
    z-index: 999;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
      cursor: pointer;
      transition: 0.15s;

      &:hover {
        color: #000;
      }
    }

    h3 {
      margin: 0;
    }

    p {
      margin: 15px 0;
    }

    .error {
      color: tomato;
      font-size: 12px;
    }

    .button {
      width: 100%;
    }
  }
}

label {
  color: $secondary;
}

h2 {
  padding: 1rem 0;
}

button {
  margin: 1rem 0;
  color: $primary;
  background: $white;
  border: 0 solid $primary;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  box-shadow: 0 0 0 0 rgba($dark, 0.5);
  cursor: pointer;

  &:hover {
    background: $lightest;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

</style>